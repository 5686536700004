import React , { useRef } from 'react'
import 'tinymce/tinymce';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/table';
// import 'tinymce/plugins/help';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/emoticons';
// import 'tinymce/plugins/mentions';
import 'tinymce/plugins/autosave';
import { Editor } from '@tinymce/tinymce-react';

type Props = {
  html?: any
  guardarEnBD: (data: string) => void;
}



const Tinymce: React.FC<Props> = ({html, guardarEnBD}) => {
    const editorRef = useRef<any>(null);

    const log = () => {
        if (editorRef.current) {
            const content = editorRef.current.getContent();
            console.log(content);
            guardarEnBD(content);
        }
    };

    return (<>
        <Editor
            
            onInit={(evt, editor:any) => editorRef.current = editor}
            initialValue={html.value}
            init={{
            height: 500,
            plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code',  'wordcount'
            ],
            menubar: 'file edit view insert format tools table ',
            toolbar: "undo redo | accordion accordionremove | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code fullscreen preview | save print | pagebreak anchor codesample | ltr rtl",
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            image_title: true,
            images_upload_url: 'postAcceptor.php',
            automatic_uploads: false,
            skin_url: 'https://cdn.jsdelivr.net/npm/tinymce/skins/ui/oxide',  
            content_css: 'https://cdn.jsdelivr.net/npm/tinymce/skins/content/default/content.min.css',  
            }}
        />
        <div className="my-2 d-flex align-items-end flex-row-reverse">
        <button className="btn btn-primary" onClick={log}>Cambiar</button>
        </div>
    </>)
}

export {Tinymce}
