import {lazy, FC, Suspense, useEffect, useState} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
// import {MyPage} from '../pages/test'
import {permisoModulo} from '../../requests/moduleRequest'

// eslint-disable-next-line
const withPermissionCheck = (Component: FC, routePath: string) => {
  return () => {
    const [hasAccess, setHasAccess] = useState<boolean | null>(null);

    useEffect(() => {
      hasPermission(routePath).then((hasPermissionValue) => {
        setHasAccess(() => hasPermissionValue); 
      });
    }, []);

    if (hasAccess === null) {
      // Puede ser una mejor opción mostrar un componente de carga en lugar de null
      return null;
    } else if (!hasAccess) {
      return <Navigate to="/error/404" />;
    } else {
      return <Component />;
    }
  };
};

async function hasPermission(routePath: string) {
  const info = await permisoModulo(routePath);
  return info?.data?.hasPermission || false;
}



const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  // const RolePage = lazy(() => import('../modules/apps/role-management/RolePage'))
  const BlogPage = lazy(() => import('../modules/apps/blog/BlogPage'))
  const DestinosPage = lazy(() => import('../modules/apps/destinos/DestinosPage'))
  const TourPage = lazy(() => import('../modules/apps/tours/TourPage'))
  const TestimoniosPage = lazy(() => import('../modules/apps/testimonios/TestimoniosPage'))
  const HotelesPage = lazy(() => import('../modules/apps/hoteles/HotelesPage'))
  const BannersPage = lazy(() => import('../modules/apps/banners/BannersPage'))
  const SliderPage = lazy(() => import('../modules/apps/slider/SliderPage'))
  const ContenidosPage = lazy(() => import('../modules/apps/contenidos/ContenidosPage'))
  const CategoriasPage = lazy(() => import('../modules/apps/categoria/CategoriasPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        
        
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route
          path='apps/blog/*'
          element={
            <SuspensedView>
              <BlogPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/destinos/*'
          element={
            <SuspensedView>
              <DestinosPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/tours/*'
          element={
            <SuspensedView>
              <TourPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/testimonios/*'
          element={
            <SuspensedView>
              <TestimoniosPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/hoteles/*'
          element={
            <SuspensedView>
              <HotelesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/banners/*'
          element={
            <SuspensedView>
              <BannersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/slider/*'
          element={
            <SuspensedView>
              <SliderPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/contenidos/*'
          element={
            <SuspensedView>
              <ContenidosPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/categorias/*'
          element={
            <SuspensedView>
              <CategoriasPage />
            </SuspensedView>
          }
        />
        
        
        {/* Lazy Modules */}
       
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        
      
        
        
       
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
