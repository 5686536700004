import axios from 'axios'
import { ModelPermisoQueryResponse} from '../models/Modulos'

const API_URL = process.env.REACT_APP_API_URL
const GetModules = `${API_URL}/modulos`

export const permisoModulo = (path: string): Promise<ModelPermisoQueryResponse> => {
  return axios.post<{hasPermission: boolean}>(`${GetModules}/permiso_modulo`, {
    path
  })
}

