const QUERIES = {
  USERS_LIST: 'users-list',
  ROLES_LIST: 'roles-list',
  BLOG_LIST: 'blog-list',
  BLOG_CATERORIES: 'blog-categorias',
  BLOG_TAGS: 'blog-tags',
  DESTINOS_LIST: 'destinos-list',
  TOUR_LIST: 'tour-list',
  TOUR_CATERORIES: 'tour-categorias',
  TOUR_TAGS: 'tour-tags',
  TOUR_DESTINOS: 'tour-destinos',
  TESTIMONIOS_LIST: 'testimonios-list',
  HOTEL_LIST: 'hotel-list',
  BANNER_LIST: 'banner-list',
  SLIDER_LIST: 'slider-list',
  TOURS_CATEGORIAS_VIAJES: 'tour-categorias-viajes',
}

export {QUERIES}
